<template>
  <q-form ref="editForm">
    <c-card title="LBLBASEINFO" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn
            v-show="editable"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="journal"
            :mappingType="saveType"
            label="LBLSAVE"
            icon="save"
            @beforeAction="saveJournal"
            @btnCallback="saveJournalCallback" 
          />
          <c-btn
            v-show="editable&&isOld"
            label="LBLREMOVE"
            icon="delete_forever"
            @btnClicked="deleteJournal"
          />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-text
            required
            :editable="editable"
            label="일지명"
            name="journalName"
            v-model="journal.journalName">
          </c-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-field 
            required
            :editable="editable"
            type="user" 
            label="확인자" 
            name="confirmUserId" 
            v-model="journal.confirmUserId" 
          />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            required
            :editable="editable"
            default="today"
            label="실시일"
            name="actionDate"
            v-model="journal.actionDate"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant 
            required
            :editable="editable"
            type="edit" 
            name="plantCd" 
            v-model="journal.plantCd" />
        </div>
      </template>
    </c-card>
    <c-table
      ref="table"
      title="소방교육 및 훈련일지 정보"
      class="q-mt-sm"
      :columns="grid.columns"
      :gridHeight="gridHeight"
      :data="journal.contents"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :hideBottom="true"
      :editable="editable"
      rowKey="sopFireFightingJournalContentsId"
      selection="multiple"
      @linkClick="openContentsPop"
    >
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn 
            v-if="editable" 
            :showLoading="false" 
            label="교육 추가" 
            icon="add" 
            @btnClicked="addContents('E')" />
          <c-btn 
            v-if="editable" 
            :showLoading="false" 
            label="훈련 추가" 
            icon="add" 
            @btnClicked="addContents('T')" />
          <c-btn 
            v-if="editable && journal.contents && journal.contents.length > 0" 
            :showLoading="false" 
            label="LBLEXCEPT" 
            icon="remove" 
            @btnClicked="removeContents" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='userCount'">
          <q-btn
            v-if="props.row[col.name]"
            class="tableinnerBtn"
            flat
            :align="col.align"
            color="blue-6"
            :label="props.row[col.name]">
            <q-popup-proxy :ref="'proxy_' + props.rowIndex + '_' + col.name">
              <component
                :is="col.component"
                :props="props"
                :col="col"
                :editable="editable"
                @callback="(data, color) => callbackProxy(data, props, col)"
              />
            </q-popup-proxy>
          </q-btn>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'fireFightingJournalDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingJournalId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      journal: {
        sopFireFightingJournalId: '',  // 소방 일지 일련번호
        plantCd: '',  // 사업장 코드
        journalName: '',  // 일지명
        confirmUserId: '',  // 확인자ID
        actionDate: '',  // 실시일
        regUserId: '',  // 등록자 ID
        contents: [],
        deleteContents: [],
      },
      grid: {
        columns: [
          {
            name: 'resultType',
            field: 'resultType',
            label: '종별',
            style: 'width:150px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'taskName',
            field: 'taskName',
            label: '교육·훈련명',
            style: 'width:150px',
            align: 'left',
            type: 'link',
            sortable: false,
          },
          {
            name: 'contents',
            field: 'contents',
            label: '내용',
            style: 'width:500px',
            align: 'left',
            type: 'textarea',
            sortable: false,
          },
          {
            name: 'userCount',
            field: 'userCount',
            label: '참가자',
            style: 'width:100px',
            align: 'center',
            type: 'custom',
            sortable: false,
            component: () => import(`${'@/pages/fft/journal/contentsEntrants.vue'}`)
          },
          {
            name: "courseEvaluation",
            field: "courseEvaluation",
            label: "강평사항",
            style: 'width:250px',
            align: "left",
            type: 'textarea',
            sortable: false,
          },
        ],
        height: '300px'
      },
      editable: true,
      isSave: false,
      saveType: 'POST',
      detailUrl: '',
      eduContentsListUrl: '',
      trainingContentsListUrl: '',
      saveUrl: '',
      deleteUrl: '',
      popupOptions: {
        target: null,
        title: '',
        isFull: false,
        visible: false,
        width: '50%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.sopFireFightingJournalId)
    },
    gridHeight() {
      let height = this.contentHeight - 200;
      if (height < 500) {
        height = 500;
      }
      return String(height) + 'px';
    }
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.fft.fireFightingJournal.get.url;
      this.eduContentsListUrl = selectConfig.sop.edu.result.fireJournal.contents.url;
      this.trainingContentsListUrl = selectConfig.sop.eap.training.fireJournal.contents.url;
      this.saveUrl = transactionConfig.sop.fft.fireFightingJournal.update.url;
      this.deleteUrl = transactionConfig.sop.fft.fireFightingJournal.delete.url;
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopFireFightingJournalId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.journal, _result.data)
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
        },);
      }
    },
    /* eslint-disable no-unused-vars */
    callbackProxy(data, props, col) {
      // if (col.name === 'beforeRisk') {
      //   this.$set(this.node.hazopScenarios[props.rowIndex], 'beforeFrequency', data.f)
      //   this.$set(this.node.hazopScenarios[props.rowIndex], 'beforeStrength', data.s)
      //   this.$set(this.node.hazopScenarios[props.rowIndex], 'beforeRisk', data.r)
      //   this.$set(this.node.hazopScenarios[props.rowIndex], 'beforeRamMatrixRiskId', data.ramMatrixRiskId)
      // } else {
      //   this.$set(this.node.hazopScenarios[props.rowIndex], 'afterFrequency', data.f)
      //   this.$set(this.node.hazopScenarios[props.rowIndex], 'afterStrength', data.s)
      //   this.$set(this.node.hazopScenarios[props.rowIndex], 'afterRisk', data.r)
      //   this.$set(this.node.hazopScenarios[props.rowIndex], 'afterRamMatrixRiskId', data.ramMatrixRiskId)
      // }
      // if (this.node.hazopScenarios[props.rowIndex].editFlag !== 'C') {
      //   this.$set(this.node.hazopScenarios[props.rowIndex], 'editFlag', 'U')
      // }
    },
    openContentsPop(row) {
      if (row.eduEducationId) {
        this.popupOptions.title = "교육계획 및 결과 상세"; // 교육계획 상세
        this.popupOptions.param = {
          eduEducationId: row.eduEducationId,
          stepCd: row.stepCd === 'ESC000001' ?  'ESC0000005' : 'ESC0000010',
        };
        this.popupOptions.target = () => import(`${"@/pages/sop/edu/result/educationResultDetail.vue"}`);
      } else {
        this.popupOptions.title = 'LBL0002663'; // 비상대응 훈련 상세
        this.popupOptions.param = {
          sopEmergencyTrainingId: row.sopEmergencyTrainingId
        }
        this.popupOptions.target = () => import(`${'@/pages/sop/eap/traningDetail.vue'}`);
      }
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    addContents(flag) {
      if (flag === 'E') {
        this.popupOptions.title = '교육';
        this.popupOptions.target = () => import(`${'@/pages/sop/edu/result/educationPop.vue'}`);
        this.popupOptions.param = {
          type: 'multiple',
          // 계획완료 & 교육완료만 표시되도록
          exceptDocumentStatusCd: 'ESC000001'
        };
        this.popupOptions.width = '80%';
        this.popupOptions.closeCallback = this.closeEduContentsPopup;
      } else {
        this.popupOptions.title = '비상대응훈련';
        this.popupOptions.target = () => import(`${'@/pages/sop/eap/traningPop.vue'}`);
        this.popupOptions.param = {
          type: 'multiple'
          // 진행상태 상관없음
        };
        this.popupOptions.width = '70%';
        this.popupOptions.closeCallback = this.closeTraningContentsPopup;
      }
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
    },
    closeEduContentsPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let pushDatas = [];
        this.$_.forEach(data, item => {
          /**
           * 종별 : [교육] ${교육종류1} / ${교육종류2}
           * 참가인원 : 교육대상자
           * 내용 : 교육내용 ${과목 및 세부내용} (${교육시간})
           * 
           * **교육 대상자 및 교육내용을 가져와야 함
           */
          if (this.$_.findIndex(this.journal.contents, { eduEducationId: item.eduEducationId }) === -1) {
            pushDatas.push(item)
          }
        })
        if (pushDatas && pushDatas.length > 0) {
          this.$http.url = this.eduContentsListUrl;
          this.$http.type = 'GET';
          this.$http.param = {
            eduEducationIds: this.$_.map(pushDatas, 'eduEducationId')
          };
          this.$http.request((_result) => {
            pushDatas = this.$_.map(pushDatas, data => {
              let contents = this.$_.find(_result.data, { eduEducationId: data.eduEducationId })
              return this.$_.extend(data, {
                contents: contents ? contents.contents : '',
                users: contents ? this.$_.map(contents.users, user => {
                  return {
                    sopFireFightingJournalContentsUserId: '',  // 소방 일지 참가자 일련번호
                    sopFireFightingJournalContentsId: '',  // 소방 일지 내용 일련번호
                    sopFireFightingJournalId: this.popupParam.sopFireFightingJournalId,  // 소방 일지 일련번호
                    userId: user.attendeesId,  // 참가자ID
                    userName: user.attendeesName,  // 참가자명
                    regUserId: this.$store.getters.user.userId,  // 등록자 ID
                    editFlag: 'C'
                  }
                }) : [],
                userCount: contents ? contents.userCount : ''
              })
            })
            this.$_.forEach(pushDatas, pushData => {
              this.journal.contents.push({
                sopFireFightingJournalContentsId: uid(),  // 소방 일지 내용 일련번호
                sopFireFightingJournalId: this.popupParam.sopFireFightingJournalId,  // 소방 일지 일련번호
                sopEmergencyTrainingId: '',  // 훈련 일련번호
                eduEducationId: pushData.eduEducationId,  // 교육 일련번호
                taskName: pushData.educationName,
                resultType: `[교육] ${pushData.educationKindCdLargeName} / ${pushData.educationKindCdSmallName}`,  // 종별
                contents: pushData.contents,  // 내용
                users: pushData.users,
                deleteUsers: [],
                regUserId: this.$store.getters.user.userId,  // 등록자 ID
                editFlag: 'C'
              })
            })
          },);
        }
      }
    },
    closeTraningContentsPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let pushDatas = [];
        this.$_.forEach(data, item => {
          /**
           * 종별 : [훈련] ${훈련구분}
           * 참가인원 : 훈련참가자
           * 내용 : 훈련상세내용 ${항목} / ${상세훈련내용} (${소요시간})
           * 
           * **훈련참가자 및 훈련상세내용을 가져와야 함
           */
          if (this.$_.findIndex(this.journal.contents, { sopEmergencyTrainingId: item.sopEmergencyTrainingId }) === -1) {
            pushDatas.push(item)
          }
        })
        if (pushDatas && pushDatas.length > 0) {
          this.$http.url = this.trainingContentsListUrl;
          this.$http.type = 'GET';
          this.$http.param = {
            sopEmergencyTrainingIds: this.$_.map(pushDatas, 'sopEmergencyTrainingId')
          };
          this.$http.request((_result) => {
            pushDatas = this.$_.map(pushDatas, data => {
              let contents = this.$_.find(_result.data, { sopEmergencyTrainingId: data.sopEmergencyTrainingId })
              return this.$_.extend(data, {
                contents: contents ? contents.contents : '',
                users: contents ? this.$_.map(contents.users, user => {
                  return {
                    sopFireFightingJournalContentsUserId: '',  // 소방 일지 참가자 일련번호
                    sopFireFightingJournalContentsId: '',  // 소방 일지 내용 일련번호
                    sopFireFightingJournalId: this.popupParam.sopFireFightingJournalId,  // 소방 일지 일련번호
                    userId: user.attendUserId,  // 참가자ID
                    userName: user.attendUserName,  // 참가자명
                    regUserId: this.$store.getters.user.userId,  // 등록자 ID
                    editFlag: 'C'
                  }
                }) : [],
                userCount: contents ? contents.userCount : ''
              })
            })
            this.$_.forEach(pushDatas, pushData => {
              this.journal.contents.push({
                sopFireFightingJournalContentsId: uid(),  // 소방 일지 내용 일련번호
                sopFireFightingJournalId: this.popupParam.sopFireFightingJournalId,  // 소방 일지 일련번호
                sopEmergencyTrainingId: pushData.sopEmergencyTrainingId,  // 훈련 일련번호
                eduEducationId: '',  // 교육 일련번호
                taskName: pushData.trainingName,
                resultType: `[훈련] ${pushData.eapTrainingTypeName}`,  // 종별
                contents: pushData.contents,  // 내용
                users: pushData.users,
                deleteUsers: [],
                regUserId: this.$store.getters.user.userId,  // 등록자 ID
                editFlag: 'C'
              })
            })
          },);
        }
      }
    },
    removeContents() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (!this.journal.deleteContents) this.journal.deleteContents = []
          if (this.$_.findIndex(this.journal.deleteContents, { sopFireFightingJournalContentsId: item.sopFireFightingJournalContentsId }) === -1
            && item.editFlag !== 'C') {
              this.journal.deleteContents.push(item)
          }
          this.journal.contents = this.$_.reject(this.journal.contents, item)
        })
        this.$refs['table'].$refs['compo-table'].clearSelection();
      }
    },
    saveJournal() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.sop.fft.fireFightingJournal.update.url;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.fft.fireFightingJournal.insert.url;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result && this.$comm.validTable(this.grid.columns, this.journal.contents)) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.journal.regUserId = this.$store.getters.user.userId
              this.journal.chgUserId = this.$store.getters.user.userId
              
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveJournalCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.sopFireFightingJournalId = result.data
      this.getDetail();
    },
    deleteJournal() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.sopFireFightingJournalId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });

    },
  }
};
</script>
